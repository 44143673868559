import React from "react";
import { renderNaira } from "src/utils/functions";

const OrderCard = ({ item }) => {
  return (
    <div className="rounded-[10px] border border-[#E6E7E9] min-h-[20rem] p-5 cursor-pointer hover:scale-[.98] transition-border transform duration-300 ease-in-out hover:transform">
      <div className="overflow-hidden w-[14rem]  mb-[2rem] mx-auto ">
        <img src={item?.photo} alt={item?.name} className=" rounded-[10px] h-[14rem] w-[14rem] object-cover " />
      </div>
      <div>

        <p className="text-primary text-[1.6rem] font-semibold capitalize tracking-tight my-4">
          {item?.name}
        </p>
        <p className="text-gray text-[1.4rem] font-medium tracking-tight">
          {renderNaira(item?.salesPrice)}
        </p>
        {item?.qty > 0 ? (
          <p className="text-gray text-[1.4rem] font-medium tracking-tight my-2">
            Quantity:<span className="ml-2">{item?.qty}</span>
          </p>
        ) : (
          <p className="text-[1.4rem] text-red-400">out of stock</p>
        )}
      </div>
    </div>
  );
};

export default OrderCard;
