import './App.css';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { Wallet, Settings, AllOrders, Footer, Landing, Login, Marketplace, SideBar, TopNav, CheckOut, Print, ErrorPage } from 'src/page';
import { Suspense } from 'react';
import ProtectedRoutesProvider, { useProtectedRoutesContext } from './context/ProtectedRoutes';
import { Loader } from './component';
import { CartProvider } from './context/CartContext';
import { useProSidebar } from 'react-pro-sidebar';
import { HiMenuAlt2 } from 'react-icons/hi';



function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        {
          path: ":code",
          element: <Login />,
        },
        {
          path: "",
          element: <Login />,
        },
        {
          path: "staff/:code",
          element: <Login staff />,
        },
        {
          path: "staff",
          element: <Login staff />,
        },


      ]
    },

    {
      path: "/",
      element: <AppLayout />,
      errorElement: <ErrorPage code="500" />,
      children: [
        {
          path: "dashboard",
          element: <Landing />,
        },
        {
          path: "marketplace",
          element: <Marketplace />,
        },
        {
          path: "checkout",
          element: <CheckOut />,
        },

        {
          path: "/account",
          element: <AccountLayout />,
          children: [
            {
              path: "wallet",
              element: <Wallet />,
            },
            {
              path: "orders",
              element: <AllOrders />,
            },
            {
              path: "settings",
              element: <Settings />,
            },
          ],
        },
      ]
    },
    {
      path: "print",
      element: <Print />,
    },
    {
      path: "*",
      element: <ErrorPage />,
    },

    {
      path: "/unauthorized",
      element: <ErrorPage code="401" />
    }

  ])

  return (
    <>
      <ProtectedRoutesProvider>
        <Suspense fallback={<Loader spin />}>
          <CartProvider>
            <RouterProvider router={router} />
          </CartProvider>
        </Suspense>
      </ProtectedRoutesProvider>
    </>
  );
}

const AppLayout = () => {
  const { user, token, school } = useProtectedRoutesContext();
  if (!user && !token && !school) return <Navigate to="/" />;

  return (
    <>
      <div className='overflow-hidden'>
        <TopNav />
        <div className='min-h-[80vh] my-[4rem]'>
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
};

export const AccountLayout = () => {
  const { toggleSidebar } = useProSidebar();
  return (
    <div className='max-w-[1400px] mx-auto p-5'>
      <div className='flex items-center justify-between mb-[3rem]'>
        <h1 className='text-primary text-[2.8rem] font-semibold '>My Account</h1>
        <div
          className="cursor-pointer min-[1000px]:hidden"
          onClick={() => toggleSidebar()}
        >
          <HiMenuAlt2 size="2.5rem" className="text-[#576378]" />
        </div>
      </div>
      <div className='flex'>

        <SideBar />

        <div className='w-full px-[2rem] md:w-[80%] md:px-[4rem]'>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const AuthLayout = () => {
  const { user, token, school } = useProtectedRoutesContext();
  if (!!user && !!token && !!school) return <Navigate to="/marketplace" />;
  return <Outlet />
};


export default App;
